<template>
  <v-navigation-drawer v-if="shouldShowDrawer" v-model="drawer" app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">Konstanta Education</v-list-item-title>
        <v-list-item-subtitle>Menu</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list>
      <v-list-item-group
        v-model="selectedMenu"
        active-class="border"
        color="green"
      >
        <v-list-item
          v-for="(item, i) in sidebarItems"
          :key="i"
          :to="{ name: item.route }"
          :value="item.title"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Sidebar",
  computed: {
    ...mapGetters("drawer", ["showDrawer", "sidebarItems"]),
    ...mapGetters("user", ["role"]),
    shouldShowDrawer() {
      if (this.role === "student") {
        return false;
      }

      return this.showDrawer;
    },
    drawer: {
      get() {
        return this.$store.getters["drawer/drawer"];
      },
      set(value) {
        this.$store.commit("drawer/setDrawer", value);
      }
    }
  },
  data() {
    return {
      selectedMenu: null
    };
  }
};
</script>
