const initialState = {
  drawer: null,
  sidebar_item_list: [
    {
      icon: "mdi-office-building",
      title: "Institute",
      route: "Institute.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-account-group",
      title: "Group",
      route: "Group.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-school",
      title: "Student",
      route: "Student.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-pencil-plus",
      title: "Subtest Type",
      route: "SubtestType.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-text-subject",
      title: "Subject",
      route: "Subject.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-file-document-multiple",
      title: "Question Pack",
      route: "QuestionPack.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-file-document-edit",
      title: "Question",
      route: "Question.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-pencil",
      title: "Test",
      route: "Test.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-pencil-box-multiple",
      title: "Subtest",
      route: "Subtest.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-account",
      title: "User",
      route: "User.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-clipboard-check",
      title: "Group Result",
      route: "Result.Group.Index",
      roles: ["admin"]
    },
    {
      icon: "mdi-clipboard-check",
      title: "Student Result",
      route: "Result.Student.Index",
      roles: ["admin"]
    }
  ],
  sidebar_items: [],
  status: false,
  navbar_title: "brouu"
};

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  actions: {
    setDrawer({ commit }, drawer) {
      commit("setDrawer", drawer);
    },
    setDrawerStatus({ commit }, status) {
      commit("setDrawerStatus", status);
    },
    setSidebarItems({ commit }, items) {
      commit("setSidebarItems", items);
    },
    setNavbarTitle({ commit }, title) {
      commit("setNavbarTitle", title);
    }
  },
  mutations: {
    setDrawer(state, drawer) {
      state.drawer = drawer;
    },
    setDrawerStatus(state, status) {
      state.status = status;
    },
    setSidebarItems(state, sidebarItems) {
      state.sidebar_items = sidebarItems;
    },
    setNavbarTitle(state, title) {
      state.navbar_title = title;
    }
  },
  getters: {
    drawer: ({ drawer }) => drawer,
    showDrawer: ({ status }) => status,
    sidebarItems: ({ sidebar_items }) => sidebar_items,
    navbarTitle: ({ navbar_title }) => navbar_title
  }
};
