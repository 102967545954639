<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4">
        <v-sheet rounded="lg" outlined>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="brown" size="36">
                <span class="white--text">{{ initials }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title> {{ displayName }}</v-list-item-title>
              <v-skeleton-loader
                v-if="groupNameLoading"
                type="text"
                :loading="true"
              />
              <v-list-item-subtitle
                @click="addGroupDialog = true"
                class="pointer-cursor"
              >
                <v-tooltip v-if="!groupNameLoading" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="group === null" color="grey lighten-1" small
                      >mdi-exclamation-thick</v-icon
                    >
                    <span v-bind="attrs" v-on="on">{{ groupName }}</span>
                  </template>
                  <span>Change group</span>
                </v-tooltip></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="8">
        <v-sheet class="pa-4" rounded="lg" outlined>
          <v-skeleton-loader
            v-if="ongoingTestLoading"
            type="heading"
            :loading="true"
          />
          <h2 v-if="!ongoingTestLoading && ongoingTests.length !== 0">
            Ongoing Tests
          </h2>
          <v-skeleton-loader
            v-if="ongoingTestLoading"
            type="list-item"
            :loading="true"
          />
          <v-list
            v-if="!ongoingTestLoading && ongoingTests.length !== 0"
            color="transparent"
          >
            <v-list-item
              v-for="test in ongoingTests"
              @click="openRunningTestDialog(test)"
              :key="test.id"
              link
            >
              <v-list-item-content>
                <v-list-item-title>{{ test.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  <v-chip
                    class="mr-2"
                    :color="testAvailabilityColor(test.status)"
                    >{{ test.status | toUpperCase }}</v-chip
                  >
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-skeleton-loader
            v-if="testLoading"
            type="heading"
            :loading="true"
          />
          <h2 v-if="!testLoading && tests.length !== 0">Tests</h2>
          <v-skeleton-loader
            v-if="testLoading"
            type="list-item"
            :loading="true"
          />
          <v-list v-if="!testLoading" color="transparent">
            <v-list-item
              v-for="test in tests"
              :key="test.id"
              @click="openTestDialog(test)"
              link
            >
              <v-list-item-content>
                <v-list-item-title>{{ test.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="test.score !== -1"
                  >scored
                  <span class="success--text">{{
                    test.score
                  }}</span></v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  <v-chip
                    class="mr-2"
                    :color="testAvailabilityColor(test.status)"
                    >{{ test.status | toUpperCase }}</v-chip
                  >
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-skeleton-loader
            v-if="completedTestLoading"
            type="heading"
            :loading="true"
          />
          <h2 v-if="!completedTestLoading && completedTests.length !== 0">
            Completed Tests
          </h2>
          <v-skeleton-loader
            v-if="completedTestLoading"
            type="list-item"
            :loading="true"
          />
          <v-list
            v-if="!completedTestLoading && completedTests.length !== 0"
            color="transparent"
          >
            <v-list-item
              v-for="test in completedTests"
              @click="openCompletedTestDialog(test)"
              :key="test.id"
              link
            >
              <v-list-item-content>
                <v-list-item-title>{{ test.name }}</v-list-item-title>
                <v-list-item-subtitle
                  >scored
                  <span class="success--text">{{
                    test.score
                  }}</span></v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  <v-chip
                    class="mr-2"
                    :color="testAvailabilityColor(test.status)"
                    >{{ test.status | toUpperCase }}</v-chip
                  >
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addGroupDialog"
      @click:outside="$refs.form.reset()"
      :persistent="addGroupLoading"
      max-width="500"
    >
      <v-card>
        <v-card-title>Update Group</v-card-title>
        <v-divider></v-divider>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submit"
        >
          <v-card-text>
            <v-text-field
              v-model="form.group_code"
              :counter="5"
              :disabled="addGroupLoading"
              :rules="rules.group_code"
              label="Group code"
              color="green"
              required
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn :loading="addGroupLoading" type="submit" color="green" text>
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="testDialog" max-width="500">
      <v-card>
        <v-card-title
          >{{ selectedTest.name }} <br />
          {{ selectedTest.duration }} minutes</v-card-title
        >
        <v-divider />
        <div class="pa-4">
          <v-skeleton-loader
            v-if="subtestsLoading"
            type="paragraph"
            :loading="true"
          />
          <div v-if="!subtestsLoading">
            <div
              v-for="(subtestList, subtestType) in subtests"
              :key="subtestType"
            >
              <span class="font-weight-medium">{{ subtestType }}</span>
              <div class="mt-2">
                <div
                  v-for="subtest in subtestList"
                  class="ml-4 mb-1"
                  :key="subtest.id"
                >
                  {{ subtest.position }}. {{ subtest.name }}
                  <div class="text--disabled">
                    {{ subtest.duration }} minutes
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!subtestsLoading"
          class="d-flex flex-column align-center pa-2"
        >
          <v-btn
            @click="startTestDialog"
            :disabled="selectedTest.status !== 'started'"
            color="success"
          >
            Start test
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ongoingTestDialog" max-width="500">
      <v-card>
        <v-card-title
          >{{ selectedTest.name }} <br />
          {{ selectedTest.test_end | dynamicTimeDifference }} left</v-card-title
        >
        <v-divider />
        <div class="pa-4">
          <v-skeleton-loader
            v-if="ongoingSubtestLoading"
            type="paragraph"
            :loading="true"
          />
          <div v-if="!ongoingSubtestLoading">
            <div
              v-for="(subtestList, subtestType) in subtests"
              :key="subtestType"
            >
              <span class="font-weight-medium">{{ subtestType }}</span>
              <div class="mt-2">
                <div
                  v-for="subtest in subtestList"
                  class="ml-4 mb-1"
                  :key="subtest.id"
                >
                  <span
                    @click="startSubtestDialog(subtest)"
                    :class="`${testAvailabilityColor(
                      subtest.status
                    )}--text pointer-cursor`"
                    >{{ subtest.position }}. {{ subtest.name }}</span
                  >
                  <div class="text--disabled">
                    {{ subtest.duration }} minutes
                    <span v-if="subtest.score !== -1"
                      >• scored
                      <span class="success--text">{{
                        subtest.score
                      }}</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!ongoingSubtestLoading"
          class="d-flex flex-column align-center pa-2"
        >
          <v-btn @click="openEndTestDialog" color="error">
            End All Tests
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="startSubtestConfirmationDialog"
      :persistent="startSubtestLoading"
      max-width="500"
    >
      <v-card>
        <v-card-title>Confirm Start Test</v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          <p>
            By clicking <b>START TEST</b> the test and the timer will be started
          </p>
          <p>
            Please note that the answer will be recorded only if
            <b>Next Question Button</b>, <b>Previous Question Button</b>, or
            <b>Question Number Button</b> clicked
          </p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="startSubtestLoading"
            @click="startSubtest"
            type="submit"
            color="green"
            text
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="startTestConfirmationDialog"
      :persistent="startTestLoading"
      max-width="500"
    >
      <v-card>
        <v-card-title>Confirm Start Test</v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          <p>
            By clicking <b>START TEST</b> the test and the timer will be started
          </p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="startTestLoading"
            @click="startTest"
            type="submit"
            color="green"
            text
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="endTestDialog"
      :persistent="endTestLoading"
      max-width="500"
    >
      <v-card>
        <v-card-title>Confirm End Test</v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          <p>
            By clicking <b>CONFIRM</b> the test and the subtests will be ended
            and calculated
          </p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="endTestLoading"
            @click="endTest"
            type="submit"
            color="green"
            text
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="completedTestDialog" max-width="500">
      <v-card>
        <v-card-title>{{ selectedTest.name }}</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-skeleton-loader
            v-if="completedSubtestLoading"
            type="paragraph"
            :loading="true"
          />
          <div v-if="!completedSubtestLoading">
            <div
              v-for="(subtestList, subtestType) in subtests"
              :key="subtestType"
            >
              <span class="font-weight-medium">{{ subtestType }}</span>
              <div class="mt-2">
                <div
                  v-for="subtest in subtestList"
                  class="ml-4 mb-1"
                  :key="subtest.id"
                >
                  <span
                    @click="reviewSubtest(subtest)"
                    :class="`${testAvailabilityColor(
                      subtest.status
                    )}--text pointer-cursor`"
                    >{{ subtest.position }}. {{ subtest.name }}</span
                  >
                  <div class="text--disabled">
                    {{ subtest.duration }} minutes
                    <span v-if="subtest.score !== -1"
                      >• scored
                      <span class="success--text">{{
                        subtest.score
                      }}</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-row justify-space-around pa-2">
          <v-btn
            @click="
              !completedSubtestLoading &&
                selectedTest.file !== null &&
                downloadFile(selectedTest.file)
            "
            color="primary"
            :disabled="
              !(!completedSubtestLoading && selectedTest.file !== null)
            "
          >
            Download Result
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="renewCertificate"
                color="success"
                :disabled="renewCertificateLoading"
                v-bind="attrs"
                v-on="on"
              >
                Renew Certificate
                <v-icon right dark> mdi-reload </v-icon>
              </v-btn>
            </template>
            <span
              >Use this button if the result is not formatted correctly
            </span>
          </v-tooltip>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.thin-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(168, 168, 168);
}

.pointer-cursor:hover {
  cursor: pointer;
}
</style>
<script>
import {
  getDate,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  format,
  lastDayOfMonth
} from "date-fns";
import { mapGetters } from "vuex";
import ResultService from "@/services/result";
import StudentService from "@/services/student";
import TestService from "@/services/test";

export default {
  name: "Dashboard.Student",
  data() {
    return {
      addGroupDialog: false,
      startTestConfirmationDialog: false,
      valid: false,
      addGroupLoading: false,
      startTestLoading: false,
      form: {
        group_code: ""
      },
      rules: {
        group_code: [
          v => !!v || "Group code is required",
          v =>
            new RegExp(/^[A-Z0-9]{5}$/).test(v) ||
            "Group code must only contain 5 alphanumeric characters in uppercase"
        ]
      },
      group: {
        name: "",
        institute: {
          name: ""
        }
      },
      tests: [],
      ongoingTests: [],
      testId: "",
      groupId: "",
      groupNameLoading: false,
      testLoading: false,
      ongoingTestLoading: false,
      testDialog: false,
      selectedTest: {
        name: ""
      },
      subtests: {},
      subtestsLoading: false,
      ongoingTestDialog: false,
      ongoingSubtestLoading: false,
      startSubtestConfirmationDialog: false,
      startSubtestLoading: false,
      selectedSubtest: {},
      endTestDialog: false,
      endTestLoading: false,
      completedTests: [],
      completedTestLoading: false,
      completedTestDialog: false,
      completedSubtestLoading: false,
      renewCertificateLoading: false
    };
  },
  filters: {
    toUpperCase(value) {
      return value.length <= 3
        ? value.toUpperCase()
        : value.charAt(0).toUpperCase() + value.slice(1);
    },
    formatDateTime(dateTime) {
      return format(new Date(dateTime), "yyyy-MM-hh HH:mm");
    },
    dynamicTimeDifference(dateTime) {
      dateTime = new Date(dateTime);
      if (differenceInSeconds(dateTime, new Date()) > 60) {
        if (differenceInMinutes(dateTime, new Date()) > 60) {
          if (differenceInHours(dateTime, new Date()) > 60) {
            if (
              differenceInDays(dateTime, new Date()) >
              getDate(lastDayOfMonth(dateTime))
            ) {
              if (differenceInMonths(dateTime, new Date() > 12)) {
                return `${differenceInYears(dateTime, new Date())} months`;
              }
              return `${differenceInMonths(dateTime, new Date())} months`;
            }
            return `${differenceInDays(dateTime, new Date())} days`;
          }
          return `${differenceInHours(dateTime, new Date())} hours`;
        }
        return `${differenceInMinutes(dateTime, new Date())} minutes`;
      }
      return `${differenceInSeconds(dateTime, new Date())} seconds`;
    },
    ellipsis(questionText) {
      return questionText.length > 100
        ? `${questionText.substring(0, 100)}...`
        : questionText;
    }
  },
  computed: {
    ...mapGetters("user", ["displayName"]),
    initials() {
      const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      const initials =
        [...localStorage.getItem("displayName").matchAll(rgx)] || [];

      return (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
    },
    isOngoingTestsExist() {
      return this.ongoingTests.length !== 0;
    },
    groupName() {
      return this.group === null
        ? "You haven't registered to any group"
        : `${this.group.name} • ${this.group.institute.name}`;
    }
  },
  async created() {
    await Promise.allSettled([
      this.findRegisteredGroup(),
      this.findTests(),
      this.findOngoingTests(),
      this.findCompletedTests()
    ]);
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.addGroupLoading = true;
        StudentService.addGroup(this.form)
          .then(async () => {
            await Promise.allSettled([
              this.findRegisteredGroup(),
              this.findTests(),
              this.findOngoingTests(),
              this.findCompletedTests()
            ]);
            this.addGroupDialog = false;
          })
          .finally(() => {
            this.addGroupLoading = false;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
          });
      }
    },
    async findRegisteredGroup() {
      this.groupNameLoading = true;
      const group = await StudentService.registeredGroup();
      this.group = group.data.data;
      this.groupNameLoading = false;
    },
    async findTests() {
      this.testLoading = true;
      const tests = await StudentService.tests();
      this.tests = tests.data.data;
      this.testLoading = false;
    },
    async findOngoingTests() {
      this.ongoingTestLoading = true;
      const tests = await StudentService.ongoingTests();
      this.ongoingTests = tests.data.data;
      this.ongoingTestLoading = false;
    },
    testAvailabilityColor(status) {
      switch (status) {
        case "not yet started":
          return "default";
        case "started":
          return "primary";
        case "ended":
          return "error";
        case "ongoing":
          return "warning";
        case "done":
          return "success";
      }
    },
    async openTestDialog(test) {
      this.testDialog = true;
      this.subtestsLoading = true;
      this.selectedTest = test;
      const subtests = await TestService.subtests(test.id);
      this.subtests = subtests.data.data;
      this.subtestsLoading = false;
    },
    async openRunningTestDialog(test) {
      this.ongoingTestDialog = true;
      this.ongoingSubtestLoading = true;
      this.selectedTest = test;
      const subtests = await TestService.runningSubtests(test.id);
      this.subtests = subtests.data.data;
      this.ongoingSubtestLoading = false;
    },
    async startTestDialog() {
      this.startTestConfirmationDialog = true;
    },
    async startTest() {
      try {
        this.startTestLoading = true;
        const test = await TestService.startTest({
          test_id: this.selectedTest.id,
          group_id: this.group.id
        });
        const testResponse = test.data.data;
        this.startTestLoading = false;
        this.startTestConfirmationDialog = false;
        this.testDialog = false;
        await Promise.allSettled([
          this.findTests(),
          this.findOngoingTests(),
          this.findCompletedTests()
        ]);
        this.selectedTest = {
          ...this.selectedTest,
          id: testResponse.id,
          test_end: testResponse.test_end
        };
        this.openRunningTestDialog(this.selectedTest);
      } catch (error) {
        this.startTestLoading = false;
      }
    },
    startSubtestDialog(subtest) {
      if (subtest.status === "ongoing") {
        this.$router.push({
          name: "Subtest.Subtest",
          params: { id: subtest.id }
        });
      }
      if (subtest.status === "done") {
        this.reviewSubtest(subtest);
      } else if (subtest.status === "started") {
        this.startSubtestConfirmationDialog = true;
        this.selectedSubtest = subtest;
      }
    },
    async startSubtest() {
      try {
        this.startSubtestLoading = true;
        const subtest = await TestService.startSubtest({
          running_test_id: this.selectedTest.id,
          subtest_id: this.selectedSubtest.id,
          test_id: this.selectedSubtest.test_id
        });
        this.startSubtestLoading = false;
        this.startSubtestConfirmationDialog = false;
        this.ongoingTestDialog = false;

        this.$router.push({
          name: "Subtest.Subtest",
          params: { id: subtest.data.data.id }
        });
      } catch (error) {
        this.startSubtestLoading = false;
      }
    },
    openEndTestDialog() {
      this.endTestDialog = true;
    },
    async endTest() {
      try {
        this.endTestLoading = true;
        await TestService.endTest(this.selectedTest.id);
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.endTestLoading = false;
        this.endTestDialog = false;
        this.ongoingTestDialog = false;
        await Promise.allSettled([
          this.findTests(),
          this.findOngoingTests(),
          this.findCompletedTests()
        ]);
      } catch (error) {
        this.endTestLoading = false;
      }
    },
    async findCompletedTests() {
      this.completedTestLoading = true;
      const tests = await StudentService.completedTests();
      this.completedTests = tests.data.data;
      this.completedTestLoading = false;
    },
    async openCompletedTestDialog(test) {
      this.completedTestDialog = true;
      this.completedSubtestLoading = true;
      this.selectedTest = test;
      const subtests = await TestService.runningSubtests(test.id);
      this.subtests = subtests.data.data;
      this.completedSubtestLoading = false;
    },
    downloadFile(url) {
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.click();
    },
    reviewSubtest(subtest) {
      this.$router.push({
        name: "Subtest.Review",
        params: { id: subtest.id }
      });
    },
    async renewCertificate() {
      this.renewCertificateLoading = true;
      try {
        const certificate = await ResultService.renewCertificate({
          running_test_id: this.selectedTest.id
        });
        this.downloadFile(certificate.data.data.file);
      } finally {
        this.renewCertificateLoading = false;
      }
    }
  }
};
</script>
