<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <Sidebar />
    <Navbar />
    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <Snackbar />
  </v-app>
</template>

<script>
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Snackbar from "./components/Snackbar";
export default {
  name: "App",
  components: {
    Sidebar,
    Navbar,
    Snackbar
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  }
};
</script>

<style>
:link {
  color: #1a73e8;
  outline: 0;
  text-decoration: none;
}
</style>
