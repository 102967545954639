import Vue from "vue";
import DateTimePicker from "vuetify-datetime-picker";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import snackbarPlugin from "./plugins/snackbar";
import { auth } from "./firebase";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;
Vue.use(snackbarPlugin, { store });
Vue.use(DateTimePicker);

let app;
//eslint-disable-next-line
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      store,
      router,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }

  if (user) {
    store.dispatch("user/setUser", user);
  } else {
    localStorage.removeItem("role");
    localStorage.removeItem("token");
  }
});
