import Vue from "vue";
import Vuex from "vuex";

import drawer from "./drawer.module";
import snackbar from "./snackbar.module";
import user from "./user.module";

import { setSidebarItems } from "./plugins/drawer.plugin";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    drawer,
    snackbar,
    user
  },
  plugins: [setSidebarItems]
});
