<template>
  <v-container>
    <v-window v-model="step">
      <v-window-item :value="'menu'">
        <v-list color="transparent">
          <v-list-item
            v-for="menu in menus"
            :key="menu.id"
            @click="step = menu.id"
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-window-item>
      <v-window-item :value="'reset-password'">
        <v-toolbar flat>
          <v-btn @click="step = 'menu'" icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ currentTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <div class="pa-4">
          <v-form
            v-model="valid"
            ref="form"
            @submit.prevent="submit"
            lazy-validation
          >
            <v-text-field
              v-model="form.current_password"
              :disabled="loading"
              :rules="rules.password"
              label="Current Password"
              type="password"
              required
            />
            <v-text-field
              v-model="form.new_password"
              :disabled="loading"
              :rules="rules.password"
              label="New Password"
              type="password"
              required
            />
            <v-text-field
              v-model="form.password_confirmation"
              :disabled="loading"
              :rules="passwordConfirmationRule"
              label="Password Confirmation"
              type="password"
              required
            />
            <v-btn
              :disabled="!valid || loading"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-form>
        </div>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { auth, credential } from "@/firebase";

export default {
  name: "Settings.Account",
  data: () => ({
    menus: [
      {
        id: "reset-password",
        title: "Reset password"
      }
    ],
    step: "menu",
    valid: false,
    loading: false,
    form: {
      current_password: "",
      new_password: "",
      password_confirmation: ""
    },
    rules: {
      password: [
        v => !!v || "Password is required",
        v =>
          (v.length >= 6 && v.length <= 50) ||
          "Password must be greater than 6 and less then 30"
      ],
      password_confirmation: [v => !!v || "Password confirmation is required"]
    }
  }),
  computed: {
    ...mapGetters("user", ["email"]),
    currentTitle() {
      return this.step !== "menu"
        ? this.menus.find(menu => menu.id === this.step).title
        : "Menu";
    },
    passwordConfirmationRule() {
      return [
        ...this.rules.password_confirmation,
        () =>
          this.form.new_password === this.form.password_confirmation ||
          "Password confirmation does not match"
      ];
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const user = auth.currentUser;
          const userCredential = credential(
            this.email,
            this.form.current_password
          );
          await user.reauthenticateWithCredential(userCredential);
          await user.updatePassword(this.form.new_password);
          this.$snackbar.showMessage({
            content: "Password changed successfully",
            variant: "success"
          });
          this.step = "menu";
        } catch (error) {
          this.$snackbar.showMessage({
            content: error.message,
            variant: "error"
          });
        }
        this.loading = false;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    }
  }
};
</script>
