import * as firebase from "firebase/app";
import "firebase/auth";

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyAPVPjPDP-8HVtnOAfLHVQBfkl7DTjY_eg",
  authDomain: "konstanta-57f8a.firebaseapp.com",
  databaseURL: "https://konstanta-57f8a.firebaseio.com",
  projectId: "konstanta-57f8a",
  storageBucket: "konstanta-57f8a.appspot.com",
  messagingSenderId: "714962718309",
  appId: "1:714962718309:web:8bf832bd0c95db7cbe808c",
  measurementId: "G-WMXSXRHJ84"
};
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const credential = firebase.auth.EmailAuthProvider.credential;

export { auth, credential };
