<template>
  <div class="page">
    <div class="form">
      <v-card class="mx-auto">
        <v-card-text class="text-center">
          <v-avatar class="mb-4" size="64">
            <img src="@/assets/konstanta.png" alt="alt" />
          </v-avatar>
          <h1 class="font-weight-bold mb-3">Sign Up</h1>
          <h3 class="text--disabled font-weight-regular mb-10">
            Create new account
          </h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-text-field
              v-model="form.name"
              :disabled="loading"
              :rules="rules.name"
              label="Name"
              prepend-icon="mdi-card-account-details-outline"
            />
            <v-menu
              v-model="dateOfBirthMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.date_of_birth"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.date_of_birth"
                  :disabled="loading"
                  label="Date of Birth"
                  prepend-icon="mdi-calendar"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date_of_birth"
                @input="dateOfBirthMenu = false"
                :max="maximumDate"
                :disabled="loading"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="form.school"
              :disabled="loading"
              :rules="rules.school"
              label="School"
              prepend-icon="mdi-office-building"
            />
            <v-select
              v-model="form.class"
              :disabled="loading"
              :items="classItems"
              :rules="rules.class"
              label="Class"
              prepend-icon="mdi-google-classroom"
            />
            <v-select
              v-model="form.group"
              :disabled="loading"
              :items="groupItems"
              :rules="rules.group"
              label="Group"
              prepend-icon="mdi-account-group"
            />
            <v-text-field
              v-model="form.phone_number"
              :disabled="loading"
              :rules="rules.phone_number"
              label="Phone Number"
              prefix="+62"
              prepend-icon="mdi-phone"
            />
            <v-text-field
              v-model="form.email"
              :disabled="loading"
              :rules="rules.email"
              label="E-mail"
              prepend-icon="mdi-email"
            />
            <v-text-field
              v-model="form.password"
              @click:append="passwordShow = !passwordShow"
              :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
              :disabled="loading"
              :rules="rules.password"
              :type="passwordShow ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-lock"
            />
            <v-text-field
              v-model="form.password_confirmation"
              @click:append="
                passwordConfirmationShow = !passwordConfirmationShow
              "
              :append-icon="
                passwordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'
              "
              :disabled="loading"
              :rules="rules.password_confirmation"
              :type="passwordConfirmationShow ? 'text' : 'password'"
              label="Password Confirmation"
              prepend-icon="mdi-lock"
            />
            <v-text-field
              v-model="form.major_option1"
              :disabled="loading"
              :rules="rules.major_option1"
              hint="ex: Teknik Informatika ITB"
              label="Major Option 1"
              prepend-icon="mdi-school"
              persistent-hint
            />
            <v-text-field
              v-model="form.major_option2"
              :disabled="loading"
              :rules="rules.major_option2"
              hint="ex: Teknik Nuklir UGM"
              label="Major Option 2"
              prepend-icon="mdi-school"
              persistent-hint
            />
            <v-text-field
              v-model="form.parents_name"
              :disabled="loading"
              :rules="rules.parents_name"
              label="Parents' Name"
              prepend-icon="mdi-account-child"
            />
            <v-text-field
              v-model="form.parents_phone_number"
              :disabled="loading"
              :rules="rules.parents_phone_number"
              label="Parents' Phone Number"
              prefix="+62"
              prepend-icon="mdi-phone"
            />
            <v-text-field
              v-model="form.parents_email"
              :disabled="loading"
              :rules="rules.parents_email"
              label="Parents' E-mail"
              prepend-icon="mdi-email"
            />
            <!-- <span class="text-body-2"
              >already have an account? sign in
              <router-link
                :to="{ name: 'Login' }"
                class="text-decoration-none font-weight-medium"
                >here</router-link
              ></span
            > -->
            <v-btn
              class="mb-2"
              color="primary"
              type="submit"
              :loading="loading"
              block
              >Sign up</v-btn
            >
            <v-btn :to="{ name: 'Login' }" block link text
              >Sign In Instead</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.form {
  width: 100%;
  max-width: 400px;
}
</style>

<script>
import AuthService from "@/services/auth";

export default {
  name: "Register",
  data() {
    return {
      passwordShow: false,
      passwordConfirmationShow: false,
      dateOfBirthMenu: false,
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone_number: "",
        date_of_birth: "",
        school: "",
        class: "",
        group: "",
        major_option1: "",
        major_option2: "",
        parents_name: "",
        parents_phone_number: "",
        parents_email: ""
      },
      rules: {
        name: [
          v => !!v || "Name is required",
          v =>
            (v.length >= 5 && v.length <= 50) ||
            "Name must be greater than 5 and less then 50",
          v =>
            new RegExp(/^\s*\w+(?:[^\w,]+\w+)*[^,\w]*$/i).test(v) ||
            "Name must only contain alphanumeric characters"
        ],
        email: [
          v => !!v || "E-mail is required",
          v =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail must be valid"
        ],
        password: [
          v => !!this.dataId || !!v || "Password is required",
          v =>
            !!this.dataId ||
            (v.length >= 6 && v.length <= 50) ||
            "Password must be greater than 6 and less then 30"
        ],
        password_confirmation: [
          v => !!this.dataId || !!v || "Confirmation assword is required",
          v =>
            !!this.dataId ||
            v === this.form.password ||
            "Password confirmation does not match"
        ],
        phone_number: [
          v => !!v || "Phone number is required",
          v =>
            /^8\d{8,11}$/.test(v) ||
            "Phone number does not match with the format"
        ],
        date_of_birth: [v => !!v || "Date of birth is required"],
        school: [
          v => !!v || "School is required",
          v =>
            (v.length >= 5 && v.length <= 50) ||
            "School must be greater than 5 and less then 50",
          v =>
            new RegExp(/^\s*\w+(?:[^\w,]+\w+)*[^,\w]*$/i).test(v) ||
            "School must only contain alphanumeric characters and dashes"
        ],
        class: [v => !!v || "Class is required"],
        group: [v => !!v || "Group is required"],
        major_option1: [
          v => !!v || "Major option 1 is required",
          v =>
            (v.length >= 5 && v.length <= 100) ||
            "Major option 1 must be greater than 5 and less then 100"
        ],
        major_option2: [
          v => !!v || "Major option 2 is required",
          v =>
            (v.length >= 5 && v.length <= 100) ||
            "Major option 2 must be greater than 5 and less then 100"
        ],
        parents_name: [
          v => !!v || "Parents' name is required",
          v =>
            (v.length >= 5 && v.length <= 50) ||
            "Parents' name must be greater than 5 and less then 50",
          v =>
            new RegExp(/^\s*\w+(?:[^\w,]+\w+)*[^,\w]*$/i).test(v) ||
            "Parents' name must only contain alphanumeric characters"
        ],
        parents_phone_number: [
          v => !!v || "Parents' phone number is required",
          v =>
            /^8\d{8,11}$/.test(v) ||
            "Parents' phone number does not match with the format"
        ],
        parents_email: [
          v => !!v || "Parents' e-mail is required",
          v =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "Parents' e-mail must be valid"
        ]
      },
      classItems: [
        "10 IPA 1",
        "10 IPA 2",
        "10 IPA 3",
        "10 IPA 4",
        "10 IPA 5",
        "10 IPA 6",
        "10 IPA 7",
        "10 IPA 8",
        "10 IPA 9",
        "10 IPA 10",
        "10 IPS 1",
        "10 IPS 2",
        "10 IPS 3",
        "10 IPS 4",
        "10 IPS 5",
        "10 IPS 6",
        "11 IPA 1",
        "11 IPA 2",
        "11 IPA 3",
        "11 IPA 4",
        "11 IPA 5",
        "11 IPA 6",
        "11 IPA 7",
        "11 IPA 8",
        "11 IPA 9",
        "11 IPA 10",
        "11 IPS 1",
        "11 IPS 2",
        "11 IPS 3",
        "11 IPS 4",
        "11 IPS 5",
        "11 IPS 6",
        "12 IPA 1",
        "12 IPA 2",
        "12 IPA 3",
        "12 IPA 4",
        "12 IPA 5",
        "12 IPA 6",
        "12 IPA 7",
        "12 IPA 8",
        "12 IPA 9",
        "12 IPA 10",
        "12 IPS 1",
        "12 IPS 2",
        "12 IPS 3",
        "12 IPS 4",
        "12 IPS 5",
        "12 IPS 6"
      ],
      groupItems: ["SAINTEK", "SOSHUM", "CAMPURAN"],
      loading: false,
      valid: false
    };
  },
  computed: {
    maximumDate() {
      const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
    }
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await AuthService.signUp({
          ...this.form,
          phone_number: `+62${this.form.phone_number}`,
          parents_phone_number: `+62${this.form.parents_phone_number}`
        });
        this.$snackbar.showMessage({
          content: "Sign up success",
          variant: "success"
        });
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>
