<template>
  <div class="page" v-if="isCodeValid">
    <div class="form">
      <v-card class="mx-auto">
        <v-card-text class="text-center">
          <v-avatar class="mb-4" size="64">
            <img src="@/assets/konstanta.png" alt="alt" />
          </v-avatar>
          <h1 class="font-weight-bold mb-3">
            Reset Password
          </h1>
          <h3 class="text--disabled font-weight-regular mb-10">
            Reset your account password
          </h3>
          <v-form
            v-model="valid"
            @submit.prevent="submit"
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-model="form.password"
              @click:append="passwordShow = !passwordShow"
              :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
              :disabled="loading"
              :rules="rules.password"
              :type="passwordShow ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-lock"
            />
            <v-text-field
              v-model="form.password_confirmation"
              @click:append="
                passwordConfirmationShow = !passwordConfirmationShow
              "
              :append-icon="
                passwordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'
              "
              :disabled="loading"
              :rules="passwordConfirmationRule"
              :type="passwordConfirmationShow ? 'text' : 'password'"
              label="Password Confirmation"
              prepend-icon="mdi-lock"
            />
            <v-btn
              :disabled="!valid"
              :loading="loading"
              class="mb-2 mt-4"
              color="primary"
              type="submit"
              block
              >Reset Password</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.form {
  width: 100%;
  max-width: 400px;
}
</style>

<script>
import { auth } from "../firebase";

export default {
  data() {
    return {
      form: {
        password: "",
        password_confirmation: ""
      },
      rules: {
        password: [
          v => !!this.dataId || !!v || "Password is required",
          v =>
            !!this.dataId ||
            (v.length >= 6 && v.length <= 50) ||
            "Password must be greater than 6 and less then 30"
        ],
        password_confirmation: [
          v => !!this.dataId || !!v || "Password confirmation is required"
        ]
      },
      code: "",
      passwordShow: false,
      passwordConfirmationShow: false,
      isCodeValid: false,
      loading: false,
      valid: false
    };
  },
  computed: {
    passwordConfirmationRule() {
      return [
        ...this.rules.password_confirmation,
        () =>
          !!this.dataId ||
          this.form.password === this.form.password_confirmation ||
          "Password confirmation does not match"
      ];
    }
  },
  async created() {
    this.code = this.$route.query.oobCode;
    const codeValidity = await this.verifyCode(this.code);
    this.isCodeValid = codeValidity;
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          const {
            code,
            form: { password }
          } = this;
          await auth.confirmPasswordReset(code, password);
          this.$snackbar.showMessage({
            content:
              "Password reset success. You can sign in using new password now",
            variant: "success"
          });
          this.$router.push({ name: "Login" });
        } catch (error) {
          this.$snackbar.showMessage({
            content: error.message,
            variant: "error"
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async verifyCode(code) {
      try {
        await auth.verifyPasswordResetCode(code);
        return true;
      } catch (error) {
        this.$snackbar.showMessage({
          content: error.message,
          variant: "error"
        });
        return false;
      }
    }
  }
};
</script>
