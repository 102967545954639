import querystring from "querystring";
import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "result";

export default {
  groupResult(groupId, testId, params) {
    return api().get(
      `${moduleName}/group/${groupId}/test/${testId}?${querystring.stringify(
        params
      )}`,
      {
        headers: authHeader()
      }
    );
  },
  generateGroupResult(groupId, testId) {
    return api().post(
      `${moduleName}/group/${groupId}/test/${testId}/generate`,
      {},
      {
        headers: authHeader()
      }
    );
  },
  testResultFile(groupId, testId) {
    return api().get(`${moduleName}/group/${groupId}/test/${testId}/file`, {
      headers: authHeader()
    });
  },
  renewCertificate(data) {
    return api().post(`${moduleName}/renew-certificate`, data, {
      headers: authHeader()
    });
  }
};
