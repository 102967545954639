const SubtestIndex = () => import("@/views/subtest/Index");
const SubtestForm = () => import("@/views/subtest/Form");
const Subtest = () => import("@/views/subtest/Subtest");
const SubtestReview = () => import("@/views/subtest/Review");

export default [
  {
    path: "/subtest",
    name: "Subtest.Index",
    component: SubtestIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/subtest/create",
    name: "Subtest.Create",
    component: SubtestForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/subtest/:id/edit",
    name: "Subtest.Edit",
    component: SubtestForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/subtest/:id/subtest",
    name: "Subtest.Subtest",
    component: Subtest,
    meta: {
      requiresAuth: true,
      roles: ["student"]
    }
  },
  {
    path: "/subtest/:id/review",
    name: "Subtest.Review",
    component: SubtestReview,
    meta: {
      requiresAuth: true,
      roles: ["student"]
    }
  }
];
